import { Options } from "vue-class-component";
import BaseTableInterface from "@/mixins/BaseTableInterface";
import BaseTableMixins from "@/mixins/BaseTable";
import MessageTableItem from "@/entity/module/MessageTableItem";
import API from "@/api/module/message";
// import { ElMessageBox } from "element-plus";

@Options({
    name: "app-module-message",
})
export default class Message extends BaseTableMixins<MessageTableItem> implements Partial<BaseTableInterface<MessageTableItem>> {
    public created(): void {
        this.setInterface(this);
        this.editTableItem = new MessageTableItem();
        this.editModalItem = new MessageTableItem();
    }

    public activated(): void {
        this.getList(1);
    }

    //后端API
    public getApi(): any {
        return API;
    }

    //排序弹窗配置
    public sortModalSetting(): void {
        this.editSortBaseTitle = "机器人排序";
    }
}
